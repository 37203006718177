import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { ICustomer } from '../../../store/recoil/customers';
import ProgressiveList from '../../TripsOverviewNew/ProgressiveList/ProgressiveList';
import Customer from './Customer';
import * as CustomersState from '../../../store/recoil/customers';
import CustomerService from '../../Customers/CustomerService';

const CUSTOMER_OFFSET = 10;

export default function List(): JSX.Element {
  const { formatMessage: f } = useIntl();
  const customers = useRecoilValue<ICustomer[]>(CustomersState.customers);
  const [filteredCustomers, setFilteredCustomers] = useRecoilState<ICustomer[]>(
    CustomersState.filteredCustomers
  );
  const selectedTempCustomers = useRecoilValue<number[]>(CustomersState.selectedTempCustomers);
  const [allCustomersSelected, setAllCustomersSelected] = React.useState<boolean>(false);
  const [serverId, setServerId] = React.useState<number | null>(null);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isPending, startTransition] = React.useTransition();
  const [initialAmountList, setInitialAmountList] = React.useState<number>(customers.length);
  const setSelectedTempCustomer = useSetRecoilState<number[]>(CustomersState.selectedTempCustomers);
  const [selectedCustomers] = useRecoilValue<number[]>(CustomersState.selectedCustomers);

  React.useEffect(() => {
    const existingSelectedCustomers: number[] = [];
    selectedTempCustomers.forEach((id: number) => {
      const customerData = customers.find((customer: ICustomer) => customer.id === id);
      if (customerData) {
        existingSelectedCustomers.push(customerData.id);
      }
    });
    if (existingSelectedCustomers.length === 0) {
      setServerId(null);
    }
    if (selectedTempCustomers.length > 0) {
      selectedTempCustomers.forEach((id: number) => {
        const customerData = customers.find((customer: ICustomer) => customer.id === id);
        if (customerData && customerData.serverId) {
          setServerId(customerData.serverId);
        }
      });
    } else {
      setServerId(null);
    }
  }, [customers, selectedCustomers, selectedTempCustomers]);

  const isCustomerSelected = (customerId: number): boolean => {
    if (selectedTempCustomers.indexOf(customerId) === -1 && !allCustomersSelected) {
      return false;
    }
    return true;
  };

  const sortCustomers = (customerToSort: ICustomer[]): ICustomer[] => {
    return [...customerToSort].sort((a, b) => {
      const aSelected = isCustomerSelected(a.id);
      const bSelected = isCustomerSelected(b.id);

      if (aSelected && !bSelected) {
        return -1;
      }

      if (!aSelected && bSelected) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
  };

  const renderRow = (index: number) => {
    const sortedCustomers = sortCustomers(filteredCustomers);

    if (sortedCustomers[index]) {
      return (
        <Customer
          key={`customer-${sortedCustomers[index].id}2`}
          customer={sortedCustomers[index]}
          selected={isCustomerSelected(sortedCustomers[index].id)}
          selectedServerId={serverId}
          alteredRowStyle={index % 2 ? '' : 'bg-gray-light'}
        />
      );
    }
    return null;
  };

  const handleCancelSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setSearchValue('');
    setFilteredCustomers(customers);
    setInitialAmountList(customers.length);
    inputRef.current?.focus();
  };

  const handleAllChoose = () => {
    if (filteredCustomers && filteredCustomers.length > 0) {
      const filteredCustomerIds = filteredCustomers.map((i) => i.id);
      if (selectedTempCustomers.length === 0) {
        setSelectedTempCustomer(filteredCustomerIds);
        setAllCustomersSelected(true);
      } else {
        setSelectedTempCustomer([]);
        setAllCustomersSelected(false);
      }
    }
  };

  React.useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers, setFilteredCustomers]);

  React.useEffect(() => {
    const checkbox = document.getElementById('checkallcustomers') as HTMLInputElement;

    if (checkbox) {
      const count: number = filteredCustomers?.length || 0;
      const selectedCustomersCount = selectedTempCustomers.length;
      if (count > 0 && selectedCustomersCount === count) {
        checkbox.checked = true;
        checkbox.indeterminate = false;
      } else if (count > 0 && selectedCustomersCount > 0) {
        checkbox.indeterminate = true;
        checkbox.checked = false;
        setAllCustomersSelected(false);
      } else {
        checkbox.indeterminate = false;
        checkbox.checked = false;
        setAllCustomersSelected(false);
      }
    }
  }, [filteredCustomers, selectedTempCustomers]);

  const debouncedCustomersSearch = debounce((value: string) => {
    if (value.length > 0) {
      startTransition(() => {
        const filtered = CustomerService.filter(customers, value);
        setFilteredCustomers(filtered);
        setInitialAmountList(filtered.length);
      });
    } else {
      setInitialAmountList(customers.length);
      setFilteredCustomers(customers);
    }
  }, 300);

  const handleDebouncedSearchCustomers = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.currentTarget.value;
    setSearchValue(value);
    debouncedCustomersSearch(value);
  };

  return (
    <>
      <div className="pt-2 bg-white" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <div className="customer-search pb-2">
          <div className="c-form-control">
            <input
              ref={inputRef}
              type="text"
              placeholder={f({ id: 'navigator.search' })}
              className="form-control"
              onChange={handleDebouncedSearchCustomers}
              data-cy="customers-search"
              style={{ paddingLeft: '36px' }}
              value={searchValue}
            />
            <i className="cmd-icon cmd-icon-search" style={{ left: '12px' }} />
            {searchValue.length > 0 && (
              <button
                onClick={handleCancelSearch}
                style={{ padding: '3px 0' }}
                className="cmd-icon cmd-icon-remove remove"
              >
                {/* &nbsp; */}
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        <div
          id="choose-all-customers"
          className="e-checkbox px-4 py-2 d-block text-black font-weight-normal"
        >
          <div className="e-checkbox">
            <label id="chooseAllLabel">
              <input
                onChange={handleAllChoose}
                type="checkbox"
                className="js-checkbox-indeterminate"
                id="checkallcustomers"
                data-cy="customers-select-all"
              />
              <span style={{ fontSize: '13px' }} id="boxCheckboxLabel">
                {' '}
                <strong>{f({ id: 'alarms.modal.timeFrame.button.selectAll' })}</strong>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div id="customers-wrapper-div" className="border-radius-xs border-on">
        {isPending ? (
          <div>{f({ id: 'navigator.loading' })}</div>
        ) : (
          // filteredCustomers.map((customer: ICustomer, key: number) => (
          //   <Customer
          //     key={`customer-${customer.id}2`}
          //     customer={customer}
          //     selected={isCustomerSelected(customer.id)}
          //     selectedServerId={serverId}
          //     alteredRowStyle={key % 2 ? '' : 'bg-gray-light'}
          //   />
          <ProgressiveList
            initialAmount={initialAmountList}
            idleAmount={CUSTOMER_OFFSET}
            progressiveAmount={filteredCustomers.length}
            renderItem={renderRow}
            rowCount={filteredCustomers.length}
            useWindowScroll
            scrollParent="customers-wrapper-div"
          />
        )}
      </div>
    </>
  );
}
