import { Axios, AxiosResponse } from 'axios';
import { URL_CAN_REFUELINGS, URL_REFUELING } from '../../../router/constants';
import * as HttpService from '../../../Services/HttpService';
import PermissionsService, {
  PERMISSION_CAN_REFUELING,
  PERMISSION_REFUELING,
} from '../../../Services/PermissionsService';
import { TABLE_CAN_REFUELING, TABLE_REFUELING } from '../../../Services/TableService';
import { ITab } from '../../TabComponent/interfaces';
import { DuplicateCanRefuelingApiResponse, IgnoreCanRefuelingApiResponse } from './types';
import showMessage from '../../Toastr/ToastService';

export const ignore = async (
  id: number,
  customerId: number
): Promise<IgnoreCanRefuelingApiResponse | false> => {
  const url = `v1/refueling/can/${id}/ignore?customerId=${customerId}`;
  const response = await HttpService.patch<unknown, AxiosResponse | false>(
    url,
    {},
    { returnAxiosResponse: true, showToaster: false, showErrorToaster: false }
  );
  if ((response as AxiosResponse).status === 200) {
    showMessage('toastr.success', 'canRefuelings.ignore.toast.successMessage', 'success', 4000);
    return (response as AxiosResponse).data as IgnoreCanRefuelingApiResponse;
  }
  showMessage('toastr.errorTitle', 'canRefuelings.ignore.toast.errorMessage', 'error', 4000);
  return false;
};

export const duplicate = async (
  id: number,
  customerId: number
): Promise<DuplicateCanRefuelingApiResponse | false> => {
  const url = `v1/refueling/can/${id}/duplicate?customerId=${customerId}`;
  const response = await HttpService.patch<unknown, AxiosResponse | false>(
    url,
    {},
    { returnAxiosResponse: true, showToaster: false, showErrorToaster: false }
  );
  if ((response as AxiosResponse).status === 200) {
    if ((response as AxiosResponse).data && (response as AxiosResponse).data.duplicate === true) {
      showMessage(
        'toastr.success',
        'canRefuelings.duplicate.toast.successMessage',
        'success',
        4000
      );
    }
    if ((response as AxiosResponse).data && (response as AxiosResponse).data.duplicate === false) {
      showMessage(
        'toastr.success',
        'canRefuelings.unduplicate.toast.successMessage',
        'success',
        4000
      );
    }
    return (response as AxiosResponse).data as DuplicateCanRefuelingApiResponse;
  }
  showMessage('toastr.errorTitle', 'canRefuelings.duplicate.toast.errorMessage', 'error', 4000);
  return false;
};

export const remove = async (id: number, customerId: number): Promise<any | false> => {
  const url = `v1/refueling/can/${id}?customerId=${customerId}`;
  const response = await HttpService.deleteRequest(url, {
    returnAxiosResponse: true,
    showToaster: false,
    showErrorToaster: false,
  });
  if ((response as AxiosResponse).status === 200) {
    showMessage('toastr.success', 'canRefuelings.remove.toast.successMessage', 'success', 4000);
    return (response as AxiosResponse).data;
  }
  showMessage('toastr.errorTitle', 'canRefuelings.remove.toast.errorMessage', 'error', 4000);
  return false;
};

export const tabsByPermission = (tabs: string[], active: string): ITab[] => {
  const enabledTabs: ITab[] = [];
  tabs.forEach((tabItem: string) => {
    if (
      tabItem === TABLE_REFUELING &&
      PermissionsService.hasAccess(PERMISSION_REFUELING, ['read'])
    ) {
      enabledTabs.push({
        name: tabItem,
        isActive: tabItem === active,
        url: `${URL_REFUELING}`,
      });
    }
    if (
      tabItem === TABLE_CAN_REFUELING &&
      PermissionsService.hasAccess(PERMISSION_CAN_REFUELING, ['read'])
    ) {
      enabledTabs.push({
        name: tabItem,
        isActive: tabItem === active,
        url: `${URL_CAN_REFUELINGS}`,
      });
    }
  });
  return enabledTabs;
};
