import { BBox } from 'geojson';
import { atom } from 'recoil';
import { IPosition } from '../../interfaces/map';
import UserService from '../../Services/UserService';
import setIsLastRidePlayerOpenToSocketServerEffect from '../../store/recoil/effects/setIsLastRidePlayerOpenToSocketServerEffect';
import { IRide, IRidePosition } from '../RidePlayer/types';
import { IUserMapSettings } from './types';
import { DEFAULT_ZOOM, MIN_POINTS_FOR_DISABLED_CLUSTER } from './GoogleMapLayer/constants';

export const zoomAtom = atom<number>({
  key: 'onlineMapState_zoom',
  default: DEFAULT_ZOOM,
});

export const autofocusAtom = atom<boolean>({
  key: 'onlineMapState_autofocus',
  default: true,
});
export const vehicleFocusAtom = atom<boolean>({
  key: 'onlineMapState_vehiclefocus',
  default: false,
});
export const isClusterEnabledAtom = atom<boolean>({
  key: 'onlineMapState_isClusterEnabled',
  default: UserService.getMapSettings().value.clusters,
});
export const isRidePlayerMenuOpenAtom = atom<boolean>({
  key: 'onlineMapState_isRidePlayerMenuOpen',
  default: false,
});
export const isRidePlayerOpenAtom = atom<number | boolean>({
  key: 'onlineMapState_isRidePlayerOpen',
  default: false,
  effects: [setIsLastRidePlayerOpenToSocketServerEffect()],
});
export const isTrafficLayerOpenAtom = atom<boolean>({
  key: 'onlineMapState_isTrafficLayerOpen',
  default: false,
});

export const isFullscreenAtom = atom<boolean>({
  key: 'onlineMapState_isFullscreen',
  default: false,
});

export const isSearchBarOpenAtom = atom<boolean>({
  key: 'onlineMapState_isSearchBarOpen',
  default: false,
});

export const isAllSimpleWindowsOpenAtom = atom<boolean>({
  key: 'onlineMapState_isAllSimpleWindowsOpen',
  default: false,
});

export const filteredVehiclesAtom = atom<number[]>({
  key: 'onlineMapState_onlineMapFilteredVehicles',
  default: [],
});

export const boundsAtom = atom<BBox | undefined>({
  key: 'onlineMapState_bounds',
  default: undefined,
});

export const centerAtom = atom<IPosition>({
  key: 'onlineMapState_center',
  default: {
    lat: 48.75946,
    lng: 17.29349,
  },
});
// export const selectedVehiclesWithData = atom<IVehicles>({
//   key: 'onlineMapState_onlineMapSelectedVehiclesWithData',
//   default: {},
// });

export const filterStringAtom = atom<string>({
  key: 'onlineMapState_filterStringOnMap',
  default: '',
});

export const mapSettingsAtom = atom<IUserMapSettings>({
  key: 'onlineMapState_mapSettings',
  default: UserService.getMapSettings(),
});

export const lastRideForSelectedVehicleAtom = atom<IRide | null>({
  key: 'onlineMapState_lastRideForSelectedVehicle',
  default: null,
});

export const currentRidePositionForSelectedVehicleAtom = atom<IRidePosition | null>({
  key: 'onlineMapState_currentRidePositionForSelectedVehicle',
  default: null,
});

export const isStreetviewEnabledAtom = atom<boolean>({
  key: 'onlineMapState_isStreetviewEnabled',
  default: false,
});

export const minPointsAtom = atom<number>({
  key: 'onlineMapMinPoints',
  default: MIN_POINTS_FOR_DISABLED_CLUSTER,
});
