import * as React from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useIntl } from 'react-intl';
import { CmdCalendar } from '@commander-services/gui-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useRoute } from 'wouter';
import DatepickerService from '../../Services/DatepickerService';
import ApiService from '../../Services/ApiService';
// import '../Forms/CmdCalendar/calendar.scss';
import { IDatepickerSettings } from '../../store/recoil/TableState';
import * as Tables from '../../store/recoil/TableState';
import useUrlQueryParams from '../../hooks/useUrlQueryParams';
import { URL_REFUELING } from '../../router/constants';
import { selectedVehiclesAtom } from '../../store/recoil/vehicles';

const TIMESTAMP_COLUMN = 'timestamp';
interface IComponentProps {
  name: string;
  type: string;
  isActive?: boolean;
  children?: JSX.Element | string;
  isLastFilter?: boolean;
  allowedRange?: Date[];
  isDayTime?: boolean;
  onClickEvent?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

dayjs.extend(utc);

export default function TableDatepicker(props: IComponentProps) {
  const { formatMessage: f } = useIntl();
  const [datepickerSettings, setDatepickerSettings] = useRecoilState<IDatepickerSettings | null>(
    Tables.setTableDatepicker
  );
  const [dateValue, setDateValue] = React.useState<Date[] | null>(null);
  const setSelectedVehicles = useSetRecoilState(selectedVehiclesAtom);

  const query = useUrlQueryParams();
  const dateId = query.get('date');
  const [, paramsVehicleId] = useRoute(`${URL_REFUELING}/:vehicleId`);
  // const vehicleId = query.get('vehicle');

  const datepickerChange = (dateValues: Date[], interval: string | null, name: string) => {
    const type = 'date_range_picker';
    setDatepickerSettings({
      ...datepickerSettings,
      [name]: { type, date: dateValues || [], interval: interval || null },
    });
  };

  const getDatepickerSettings = async (name: string) => {
    const response = await DatepickerService.getSettings();
    if (response && response.value) {
      const { value } = response;

      if (value.dateLow && value.dateHigh) {
        datepickerChange([value.dateLow, value.dateHigh], value.interval || null, name);
      }
    }
  };

  const datepickerSave = (dateValues: Date[], interval: string | null, name: string) => {
    if (dateValues.length === 2) {
      if (name === TIMESTAMP_COLUMN) {
        const inputs = {
          interval,
          dateLow: dateValues[0],
          dateHigh: dateValues[1],
        };
        const link = '/v1/user-settings/date_range_picker/save';
        ApiService.post(link, inputs);
      }
    }
  };

  const getStartDate = (date: Date) => {
    return new Date(date.setHours(0, 0, 0, 0));
  };

  const getEndDate = (date: Date) => {
    const today = new Date();
    if (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    ) {
      return new Date();
    }
    return new Date(date.setHours(23, 59, 59, 999));
  };

  const getIsEndDateToday = (date: Date) => {
    const today = new Date();
    if (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  const getInterval = (date: Date) => {
    const parseDateToday0 = new Date(new Date().setHours(0, 0, 0, 0));
    const parseDateLastSeven = new Date(new Date().setDate(new Date().getDate() - 6));
    const parseDateLastSeven0 = new Date(parseDateLastSeven.setHours(0, 0, 0, 0));
    const parseDateThisMonth0 = new Date(date.getFullYear(), date.getMonth(), 1);
    const parseDateThisYear0 = new Date(date.getFullYear(), 0);
    let interval: string | null = null;
    switch (String(date)) {
      case String(parseDateToday0):
        interval = 'TODAY';
        break;
      case String(parseDateLastSeven0):
        interval = 'LAST_WEEK';
        break;
      case String(parseDateThisYear0):
        interval = 'THIS_YEAR';
        break;
      case String(parseDateThisMonth0):
        interval = 'THIS_MONTH';
        break;

      default:
    }
    return interval;
  };

  const changeCalendarValue = (value: Date[]): void => {
    let interval: string | null = null;
    if (value && value.length === 2) {
      const startDate = getStartDate(value[0]);
      if (getIsEndDateToday(value[1])) {
        interval = getInterval(startDate);
      }
      const newValue = [getStartDate(startDate), getEndDate(value[1])];
      if (datepickerSettings) {
        const settings = {
          ...datepickerSettings,
          [props.name]: { ...datepickerSettings[props.name], date: newValue, interval },
        };
        setDatepickerSettings(settings);
      } else {
        const settings = {
          [props.name]: { date: newValue, type: 'date_range_picker', interval },
        };
        setDatepickerSettings(settings);
      }
      datepickerSave(newValue, interval, props.name);
    }
  };

  const handleDateFromUrl = (dateKey) => {
    // Helper function to parse date string to Date object
    const parseDate = (dateStr) => {
      return dayjs(dateStr).toDate();
    };

    if (typeof dateKey === 'string' && dateKey) {
      // Split the dateKey by comma
      const dates = dateKey.split(',').map(parseDate);

      if (dates.length === 1) {
        // If there's only one date, use it for both start and end
        changeCalendarValue([dates[0], dates[0]]);
      } else if (dates.length === 2) {
        // If there are two dates, use them as start and end
        changeCalendarValue([dates[0], dates[1]]);
      }
    }
  };

  React.useEffect(() => {
    if (
      (!datepickerSettings || (datepickerSettings && !datepickerSettings[props.name])) &&
      props.isActive
    ) {
      if (!dateId) {
        getDatepickerSettings(props.name);
      }
      if (dateId) {
        handleDateFromUrl(dateId);
      }
      if (paramsVehicleId && !Number.isNaN(Number(paramsVehicleId.vehicleId))) {
        setSelectedVehicles([Number(paramsVehicleId.vehicleId)]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      datepickerSettings &&
      props.name &&
      datepickerSettings[props.name] &&
      datepickerSettings[props.name].date &&
      datepickerSettings[props.name].date.length === 2
    ) {
      setDateValue(datepickerSettings[props.name].date);
    } else {
      setDateValue(null);
    }
  }, [datepickerSettings, props.name]);

  const menuTranslation: {
    [key: string]: string;
  } = {
    TODAY: f({ id: 'datepicker.TODAY' }),
    YESTERDAY: f({ id: 'datepicker.YESTERDAY' }),
    LAST_WEEK: f({ id: 'datepicker.LAST_WEEK' }),
    THIS_MONTH: f({ id: 'datepicker.THIS_MONTH' }),
    PREVIOUS_MONTH: f({ id: 'datepicker.PREVIOUS_MONTH' }),
    THIS_YEAR: f({ id: 'datepicker.THIS_YEAR' }),
    PREVIOUS_YEAR: f({ id: 'datepicker.PREVIOUS_YEAR' }),
  };

  if (props.isDayTime) {
    menuTranslation.DATE_TITLE = f({ id: 'datepicker.date' });
    menuTranslation.DAYS_TITLE = f({ id: 'datepicker.date.dayTime.title' });
    menuTranslation.PICK_DAYS_MODAL = f({ id: 'datepicker.date.dayTime.select' });
  }

  return (
    <div
      id={`tableDatepicker-${props.name}`}
      className="input-actions flatpickr-inline"
      role="presentation"
      style={{ position: 'relative', overflow: 'initial', maxWidth: '100%'}}
    >
      <CmdCalendar
        id={`tableDatepicker-${props.name}-${props.type}`}
        mode="filter"
        initialValue={dateValue}
        onChangeCallback={changeCalendarValue}
        language={localStorage.getItem('language') || undefined}
        rangesTranslations={{
          DATE_TITLE: f({ id: 'datepicker.date' }),
          TODAY: f({ id: 'datepicker.TODAY' }),
          YESTERDAY: f({ id: 'datepicker.YESTERDAY' }),
          LAST_WEEK: f({ id: 'datepicker.LAST_WEEK' }),
          THIS_MONTH: f({ id: 'datepicker.THIS_MONTH' }),
          PREVIOUS_MONTH: f({ id: 'datepicker.PREVIOUS_MONTH' }),
          THIS_YEAR: f({ id: 'datepicker.THIS_YEAR' }),
          PREVIOUS_YEAR: f({ id: 'datepicker.PREVIOUS_YEAR' }),
          DAYS_TITLE: f({ id: 'datepicker.date.dayTime.title' }),
          PICK_DAYS_MODAL: f({ id: 'datepicker.date.dayTime.select' }),
        }}
        calendarTranslations={{
          sunday: f({ id: 'date.sunday' }).slice(0, 2),
          monday: f({ id: 'date.monday' }).slice(0, 2),
          tuesday: f({ id: 'date.tuesday' }).slice(0, 2),
          wednesday: f({ id: 'date.wednesday' }).slice(0, 2),
          thursday: f({ id: 'date.thursday' }).slice(0, 2),
          friday: f({ id: 'date.friday' }).slice(0, 2),
          saturday: f({ id: 'date.saturday' }).slice(0, 2),
          ok: f({ id: 'table.apply' }),
          today: f({ id: 'datepicker.TODAY' }),
          yesterday: f({ id: 'datepicker.YESTERDAY' }),
          // hours: f({ id: 'date.hours' }),
          // minutes: f({ id: 'date.minutes' }),
          // seconds: f({ id: 'date.seconds' }),
        }}
        positionRight={!!props.isLastFilter}
        allowedRange={props.allowedRange}
        dateFormat="dd.MM.yyyy"
        isDayTime={props.isDayTime}
        onClickEvent={props.onClickEvent}
      >
        {props.children}
      </CmdCalendar>
    </div>
  );
}
