import localforage from 'localforage';
import { ICookieConsent } from '../components/CookieConsent/interfaces';
import { IUserMapSettings } from '../components/OnlineMapNew/types';

const EVENTS_KEY = 'events';

const UserService = {
  getUsername(): string | null {
    return localStorage.getItem('username');
  },

  getCustomerId(): number | null {
    const customerId = localStorage.getItem('customerId');
    if (!customerId) {
      return null;
    }
    return Number(customerId);
  },

  getUserId(): number | null {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      return null;
    }
    return Number(userId);
  },

  getDriverId(): number | null {
    const driverId = localStorage.getItem('driverId');
    if (!driverId) {
      return null;
    }
    return Number(driverId);
  },

  async getUserIdFromLocalForage(): Promise<number | null> {
    const userId = await localforage.getItem<number>('userId');
    if (!userId) {
      return null;
    }
    return Number(userId);
  },

  async getDriverIdFromLocalForage(): Promise<number | null> {
    const driverId = await localforage.getItem<number>('driverId');
    if (!driverId) {
      return null;
    }
    return Number(driverId);
  },

  getUserEmail(): string | null {
    const userEmail = localStorage.getItem('userEmail');
    if (!userEmail) {
      return null;
    }
    return userEmail;
  },

  setUserId(id: number): void {
    localforage.setItem('userId', Number(id));
    return localStorage.setItem('userId', String(id));
  },

  async setDriverIdInIndexedDb(id: number | null) {
    await localforage.setItem('driverId', id ? Number(id) : 0);
  },

  setDriverId(id: number | null): void {
    return localStorage.setItem('driverId', String(id));
  },

  setUserEmail(email: string): void {
    return localStorage.setItem('userEmail', email);
  },

  setUsername(username: string): void {
    return localStorage.setItem('username', username);
  },

  setDefaultCustomer(selectedCustomers: number[]): void {
    return localStorage.setItem('defaultCustomer', JSON.stringify(selectedCustomers));
  },

  getDefaultCustomer(): number[] {
    const defaultCustomer = localStorage.getItem('defaultCustomer');
    if (defaultCustomer) {
      return JSON.parse(defaultCustomer);
    }
    return [];
  },

  setCustomerId(customerId: number): void {
    return localStorage.setItem('customerId', String(customerId));
  },

  setLanguage(language: string): void {
    return localStorage.setItem('language', language);
  },

  getLanguage(): string {
    const language = localStorage.getItem('language');

    if (language) {
      return language;
    }

    return '';
  },

  setSpeedLimitSettings(settings: Record<string, any>): void {
    return localStorage.setItem('speedLimitSettings', JSON.stringify(settings));
  },

  getSpeedLimitSettings(): any {
    const speedLimitSettings = localStorage.getItem('speedLimitSettings');
    if (speedLimitSettings) {
      return JSON.parse(speedLimitSettings);
    }
    console.warn("Configuration for speedlimits doesn't exists.");
    return null;
  },

  setMapSettings(settings: IUserMapSettings): void {
    if (!settings.value.mapType) {
      settings.value.mapType = 'roadmap';
    }
    return localStorage.setItem('mapSettings', JSON.stringify(settings));
  },

  getMapSettings(): IUserMapSettings {
    const mapSettings = localStorage.getItem('mapSettings');

    if (mapSettings) {
      const tempMapSettings: IUserMapSettings = JSON.parse(mapSettings);
      const events = JSON.parse(mapSettings).value.events;

      if (!tempMapSettings.value.mapType) {
        tempMapSettings.value.mapType = 'roadmap';
      }

      if (typeof events === 'boolean' || !(EVENTS_KEY in tempMapSettings.value)) {
        tempMapSettings.value.events = {
          enabled: true,
          doors: true,
          additional: true,
          bluecoins: true,
        };
        tempMapSettings.value.clusters = true;

        return tempMapSettings;
      }

      return JSON.parse(mapSettings);
    }

    return {
      customerId: null,
      id: null,
      key: 'map_settings',
      value: {
        identification: true,
        licenceNumber: true,
        driverName: true,
        vehicleName: true,
        alarms: true,
        events: {
          enabled: true,
          doors: true,
          additional: true,
          bluecoins: true,
        },
        clusters: true,
        mapType: 'roadmap',
      },
    };
  },

  setCookieConsent(cookieConsent: ICookieConsent | null): void {
    localStorage.setItem('cookieConsent', cookieConsent ? JSON.stringify(cookieConsent) : '');
  },

  getCookieConsent(): ICookieConsent | null {
    const cookieConsent = localStorage.getItem('cookieConsent');
    return cookieConsent ? JSON.parse(cookieConsent) : null;
  },

  logOut(): void {
    const language = this.getLanguage();
    localStorage.clear();
    localforage.clear();
    this.setLanguage(language);
    window.location.href = '/';
  },

  autoLogOut(): void {
    const language = this.getLanguage();
    localStorage.clear();
    localforage.clear();
    this.setLanguage(language);
    window.location.href = '/?autologout=true';
  },

  isLogged(): boolean {
    return !(
      localStorage.getItem('socketServerToken') === null &&
      localStorage.getItem('appPermisions') === null
    );
  },
};

export default UserService;
