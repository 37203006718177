/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import GoogleMapsStyle from '../components/OnlineMapNew/GoogleMapLayer/GoogleMapsStyle';
import IconService from './IconService';
import SvgIconService from './SvgIconService';
import {
  IVehicle,
  IVehicleRidePosition,
  TVehicleRidePositionType,
} from '../components/Vehicles/types';
import { IRide, IRidePosition } from '../components/RidePlayer/types';

const DRIVING330Svg = SvgIconService.driving330();
const DRIVING330YellowSvg = SvgIconService.driving330Yellow();
const DRIVING330RedSvg = SvgIconService.driving330Red();
const DRIVING330GreenSvg = SvgIconService.driving330Green();
const DRIVING330BlueSvg = SvgIconService.driving330Blue();
const DRIVING300Svg = SvgIconService.driving300();
const DRIVING300YellowSvg = SvgIconService.driving300Yellow();
const DRIVING300RedSvg = SvgIconService.driving300Red();
const DRIVING300GreenSvg = SvgIconService.driving300Green();
const DRIVING300BlueSvg = SvgIconService.driving300Blue();
const DRIVING270Svg = SvgIconService.driving270();
const DRIVING270YellowSvg = SvgIconService.driving270Yellow();
const DRIVING270RedSvg = SvgIconService.driving270Red();
const DRIVING270GreenSvg = SvgIconService.driving270Green();
const DRIVING270BlueSvg = SvgIconService.driving270Blue();
const DRIVING240Svg = SvgIconService.driving240();
const DRIVING240YellowSvg = SvgIconService.driving240Yellow();
const DRIVING240RedSvg = SvgIconService.driving240Red();
const DRIVING240GreenSvg = SvgIconService.driving240Green();
const DRIVING240BlueSvg = SvgIconService.driving240Blue();
const DRIVING210Svg = SvgIconService.driving210();
const DRIVING210YellowSvg = SvgIconService.driving210Yellow();
const DRIVING210RedSvg = SvgIconService.driving210Red();
const DRIVING210GreenSvg = SvgIconService.driving210Green();
const DRIVING210BlueSvg = SvgIconService.driving210Blue();
const DRIVING180Svg = SvgIconService.driving180();
const DRIVING180YellowSvg = SvgIconService.driving180Yellow();
const DRIVING180RedSvg = SvgIconService.driving180Red();
const DRIVING180GreenSvg = SvgIconService.driving180Green();
const DRIVING180BlueSvg = SvgIconService.driving180Blue();
const DRIVING150Svg = SvgIconService.driving150();
const DRIVING150YellowSvg = SvgIconService.driving150Yellow();
const DRIVING150RedSvg = SvgIconService.driving150Red();
const DRIVING150GreenSvg = SvgIconService.driving150Green();
const DRIVING150BlueSvg = SvgIconService.driving150Blue();
const DRIVING120Svg = SvgIconService.driving120();
const DRIVING120YellowSvg = SvgIconService.driving120Yellow();
const DRIVING120RedSvg = SvgIconService.driving120Red();
const DRIVING120GreenSvg = SvgIconService.driving120Green();
const DRIVING120BlueSvg = SvgIconService.driving120Blue();
const DRIVING90Svg = SvgIconService.driving90();
const DRIVING90YellowSvg = SvgIconService.driving90yellow();
const DRIVING90RedSvg = SvgIconService.driving90red();
const DRIVING90GreenSvg = SvgIconService.driving90green();
const DRIVING90BlueSvg = SvgIconService.driving90blue();
const DRIVING60Svg = SvgIconService.driving60();
const DRIVING60YellowSvg = SvgIconService.driving60Yellow();
const DRIVING60RedSvg = SvgIconService.driving60Red();
const DRIVING60GreenSvg = SvgIconService.driving60Green();
const DRIVING60BlueSvg = SvgIconService.driving60Blue();
const DRIVING30Svg = SvgIconService.driving30();
const DRIVING30YellowSvg = SvgIconService.driving30Yellow();
const DRIVING30RedSvg = SvgIconService.driving30Red();
const DRIVING30GreenSvg = SvgIconService.driving30Green();
const DRIVING30BlueSvg = SvgIconService.driving30Blue();
const DRIVING0Svg = SvgIconService.driving0();
const DRIVING0YellowSvg = SvgIconService.driving0Yellow();
const DRIVING0RedSvg = SvgIconService.driving0Red();
const DRIVING0GreenSvg = SvgIconService.driving0Green();
const DRIVING0BlueSvg = SvgIconService.driving0Blue();
const START_STOP_PAUSESvg = SvgIconService.startStopPause();
const ALARMSvg = SvgIconService.alarm();
const STARTSvg = SvgIconService.start();
const PAUSESvg = SvgIconService.pause();
const STOPSvg = SvgIconService.stop();
// import configJs from '../../config';

interface IRideMapIcons {
  [index: string]: string;
}

interface IRideMapDrivingIcons {
  [index: string]: {
    [index: string]: string;
  };
}

const rideMapDrivingIcons: IRideMapDrivingIcons = {
  0: {
    default: DRIVING0Svg,

    yellow: DRIVING0YellowSvg,

    blue: DRIVING0BlueSvg,

    red: DRIVING0RedSvg,

    green: DRIVING0GreenSvg,
  },

  30: {
    default: DRIVING30Svg,

    yellow: DRIVING30YellowSvg,

    blue: DRIVING30BlueSvg,

    red: DRIVING30RedSvg,

    green: DRIVING30GreenSvg,
  },

  60: {
    default: DRIVING60Svg,

    yellow: DRIVING60YellowSvg,

    blue: DRIVING60BlueSvg,

    red: DRIVING60RedSvg,

    green: DRIVING60GreenSvg,
  },

  90: {
    default: DRIVING90Svg,

    yellow: DRIVING90YellowSvg,

    blue: DRIVING90BlueSvg,

    red: DRIVING90RedSvg,

    green: DRIVING90GreenSvg,
  },

  120: {
    default: DRIVING120Svg,

    yellow: DRIVING120YellowSvg,

    blue: DRIVING120BlueSvg,

    red: DRIVING120RedSvg,

    green: DRIVING120GreenSvg,
  },

  150: {
    default: DRIVING150Svg,

    yellow: DRIVING150YellowSvg,

    blue: DRIVING150BlueSvg,

    red: DRIVING150RedSvg,

    green: DRIVING150GreenSvg,
  },

  180: {
    default: DRIVING180Svg,

    yellow: DRIVING180YellowSvg,

    blue: DRIVING180BlueSvg,

    red: DRIVING180RedSvg,

    green: DRIVING180GreenSvg,
  },

  210: {
    default: DRIVING210Svg,

    yellow: DRIVING210YellowSvg,

    blue: DRIVING210BlueSvg,

    red: DRIVING210RedSvg,

    green: DRIVING210GreenSvg,
  },

  240: {
    default: DRIVING240Svg,

    yellow: DRIVING240YellowSvg,

    blue: DRIVING240BlueSvg,

    red: DRIVING240RedSvg,

    green: DRIVING240GreenSvg,
  },

  270: {
    default: DRIVING270Svg,

    yellow: DRIVING270YellowSvg,

    blue: DRIVING270BlueSvg,

    red: DRIVING270RedSvg,

    green: DRIVING270GreenSvg,
  },

  300: {
    default: DRIVING300Svg,

    yellow: DRIVING300YellowSvg,

    blue: DRIVING300BlueSvg,

    red: DRIVING300RedSvg,

    green: DRIVING300GreenSvg,
  },

  330: {
    default: DRIVING330Svg,

    yellow: DRIVING330YellowSvg,

    blue: DRIVING330BlueSvg,

    red: DRIVING330RedSvg,

    green: DRIVING330GreenSvg,
  },

  360: {
    default: DRIVING0Svg,

    yellow: DRIVING0YellowSvg,

    blue: DRIVING0BlueSvg,

    red: DRIVING0RedSvg,

    green: DRIVING0GreenSvg,
  },
};

const rideMapIcons: IRideMapIcons = {
  START: STARTSvg,
  STOP: STOPSvg,
  PAUSE: PAUSESvg,
  ALARM: ALARMSvg,
  START_STOP_PAUSE: START_STOP_PAUSESvg,
};

type TToggleInfoWindow = (rideNumber: number, positionIndex: number) => void;

export type TToggleOnlinePanel = () => void;

const createHTMLMapMarker = ({ OverlayView = google.maps.OverlayView, ...args }) => {
  class HTMLMapMarker extends google.maps.OverlayView {
    private latlng: google.maps.LatLng;

    private html: any;

    private div: any;

    constructor() {
      super();
      this.latlng = args.latLng;
      this.html = args.html;
      this.setMap(args.map);
    }

    createDiv() {
      this.div = document.createElement('div');
      this.div.style.position = 'absolute';

      // if (this.div) {
      //   const root = createRoot(this.div);
      //   root.render(this.html);
      // }

      ReactDOM.render(this.html, this.div);
      // if (this.html) {
      //   this.div.innerHTML = this.html;
      // }
      google.maps.event.addListener(this.div, 'click', (event: any) => {
        google.maps.event.trigger(this, 'click');
      });
    }

    appendDivToOverlay() {
      const panes = this.getPanes();
      if (panes) {
        if (args.floatPane) {
          panes.floatPane.appendChild(this.div);
        } else {
          panes.overlayMouseTarget.appendChild(this.div);
        }
      }
    }

    positionDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const infoWindowWidth = this.div.childNodes[0].offsetWidth;
      const infoWindowHeight = this.div.childNodes[0].offsetHeight;
      if (point) {
        this.div.style.left = `${point.x - infoWindowWidth / 2}px`;
        this.div.style.top = `${point.y - infoWindowHeight}px`;
      }
    }

    positionBottomLeftDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const infoWindowWidth = this.div.childNodes[0].offsetWidth;
      const infoWindowHeight = this.div.childNodes[0].offsetHeight;
      if (point) {
        this.div.style.left = `${point.x - infoWindowWidth}px`;
        this.div.style.bottom = `${point.y - infoWindowHeight}px`;
      }
    }

    positionTopLeftDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const infoWindowWidth = this.div.childNodes[0].offsetWidth;
      const infoWindowHeight = this.div.childNodes[0].offsetHeight;
      if (point) {
        this.div.style.left = `${point.x - infoWindowWidth}px`;
        this.div.style.top = `${point.y - infoWindowHeight}px`;
      }
    }

    isNumberIcon() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      if (point) {
        this.div.style.left = `${point.x - 14}px`;
        this.div.style.top = `${point.y - 14}px`;
      }
    }

    positionBottomRightDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const infoWindowWidth = this.div.childNodes[0].offsetWidth;
      const infoWindowHeight = this.div.childNodes[0].offsetHeight;
      if (point) {
        // this.div.style.right = `${point.x - infoWindowWidth}px`;
        // this.div.style.bottom = `${point.y - infoWindowHeight}px`;
        this.div.style.right = `0px`;
        this.div.style.bottom = `0px`;
      }
    }

    positionTopRightDiv() {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const infoWindowWidth = this.div.childNodes[0].offsetWidth;
      const infoWindowHeight = this.div.childNodes[0].offsetHeight;
      if (point) {
        this.div.style.right = `${point.x - infoWindowWidth}px`;
        this.div.style.top = `${point.y - infoWindowHeight}px`;
      }
    }

    draw() {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      if (args.position && args.position.bottomLeft) {
        this.positionBottomLeftDiv();
      } else if (args.position && args.position.topLeft) {
        this.positionTopLeftDiv();
      } else if (args.position && args.position.topRight) {
        this.positionTopRightDiv();
      } else if (args.position && args.position.bottomRight) {
        this.positionBottomRightDiv();
      } else if (args.position && args.position.isNumberIcon) {
        this.isNumberIcon();
      } else {
        this.positionDiv();
      }
    }

    remove() {
      if (this.div) {
        if (this.div.parentNode) {
          this.div.parentNode.removeChild(this.div);
        }
        this.div = null;
      }
    }

    getPosition() {
      return this.latlng;
    }

    getDraggable() {
      return false;
    }
  }

  return new HTMLMapMarker();
};

const GoogleMapsService = {
  /** Creates GoogleMap */
  createMap(
    googleMapId: HTMLElement | null,
    zoom: number,
    center: google.maps.LatLng,
    options?: google.maps.MapOptions
  ): google.maps.Map | null {
    if (!googleMapId) {
      console.warn('GoogleMap element not exists.');
      return null;
    }
    return new google.maps.Map(googleMapId, {
      ...options,
      zoom,
      center,
      styles: GoogleMapsStyle, // Custom styles for Google Map
      disableDefaultUI: false, // Enable/disable default Google Maps UI
      clickableIcons: false, // Enable/disable clickable icons for POIs
    });
  },

  getAzimuth(angle: number): number {
    return Math.round(angle / 30) * 30;
  },

  getRideMarkerIcon(positionType: TVehicleRidePositionType) {
    return rideMapIcons[positionType];
  },

  getAlarmMarkerIcon() {
    const icon = {
      url: rideMapIcons.ALARM,
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 40),
    };
    return icon;
  },

  getRideMarkerDrivingIconByAngle(angle: number): string {
    const azimuth = this.getAzimuth(angle);
    return rideMapIcons.default[azimuth];
  },

  getRideMarkerDrivingIconByAngleAndSpeedLimit(
    angle: number,
    speed: number,
    speedLimitSettings: any,
    speedLimitsEnabled?: boolean
  ): string {
    const azimuth = this.getAzimuth(angle);
    let color = 'default';
    if (speedLimitSettings && speedLimitsEnabled) {
      color = this.getColorBySpeedLimit(speed, speedLimitSettings);
    }
    const icon = rideMapDrivingIcons[azimuth][color];
    return icon;
  },

  getColorBySpeedLimit(speed: number, speedLimitSettings: any): string {
    const speedLimitValues = Object.keys(speedLimitSettings).sort(
      (value1: string, value2: string) => Number(value2) - Number(value1)
    );

    let color = speedLimitSettings[speedLimitValues[speedLimitValues.length - 1]];

    speedLimitValues.map((speedLimit: string) => {
      if (speed <= Number(speedLimit) && speedLimit !== '>') {
        color = speedLimitSettings[speedLimit];
      }
    });

    return color;
  },

  createPolyline(
    googleMap: google.maps.Map | null | undefined,
    path?: google.maps.LatLng[],
    color?: string,
    opacity?: number,
    size?: number,
    zIndex?: number
  ): google.maps.Polyline {
    const polyline = new google.maps.Polyline({
      geodesic: true,
      strokeColor: color || '#986778',
      strokeWeight: size || 2,
      strokeOpacity: opacity || 1.0,
      path: path || [],
      map: googleMap || undefined,
      zIndex: zIndex || 1,
    });
    return polyline;
  },

  createVehicleMarker(
    googleMap: google.maps.Map,
    vehicle: IVehicle,
    position: google.maps.LatLng,
    angle: number,
    handleSelectVehicle: (vehicleId: number) => void
  ): google.maps.Marker {
    const icon: google.maps.Icon = {
      url: IconService.getVehicleIcon(
        vehicle.vehicleTypeId,
        vehicle.status || 'UNKNOWN',
        angle,
        true
      ),
      scaledSize: new google.maps.Size(50, 50),
      anchor: new google.maps.Point(25, 25),
    };
    const marker = new google.maps.Marker({ icon, position, map: googleMap });
    marker.addListener('click', () => {
      handleSelectVehicle(vehicle.id);
    });
    return marker;
  },

  // createWaypointMarker(
  //   googleMap: google.maps.Map,
  //   position: google.maps.LatLng,
  //   waypoint: IWaypoint
  // ): google.maps.Marker {
  //   const icon = {
  //     url: WaypointIconService.getWaypointIcon(
  //       waypoint.type === 'private',
  //       1,
  //       waypoint.icon ? waypoint.icon : ''
  //     ),
  //   };
  //   const marker = new google.maps.Marker({ icon, position, map: googleMap });
  //   marker.addListener('click', () =>
  //     store.dispatch(onlineMap.setWaypointId(waypoint.id ? waypoint.id : false))
  //   );
  //   marker.addListener('mouseover', () => store.dispatch(onlineMap.setOnHoverWaypointId(waypoint)));
  //   marker.addListener('mouseout', () => store.dispatch(onlineMap.setOnHoverWaypointId(null)));
  //   return marker;
  // },

  /** Creates ride marker on GoogleMap */
  createRideMarker(
    googleMap: google.maps.Map | null,
    position: google.maps.LatLng,
    positionType: TVehicleRidePositionType,
    rotation: number,
    rideNumber: number,
    positionIndex: number,
    toggleInfoWindowCallback: TToggleInfoWindow,
    speed: number,
    speedLimitSettings: any,
    speedLimitsEnabled?: boolean
  ): google.maps.Marker | null {
    let icon = '';
    if (positionType === 'DRIVING') {
      icon = this.getRideMarkerDrivingIconByAngleAndSpeedLimit(
        rotation,
        speed,
        speedLimitSettings,
        speedLimitsEnabled
      );
    } else {
      icon = this.getRideMarkerIcon(positionType);
    }
    if (!googleMap) {
      return null;
    }
    const marker = new google.maps.Marker({ icon, position, map: googleMap });
    marker.addListener('click', () => toggleInfoWindowCallback(rideNumber, positionIndex));
    return marker;
  },

  /** Creates alarm marker */
  createAlarmMarker(
    googleMap: google.maps.Map,
    position: google.maps.LatLng,
    rideNumber: number,
    positionIndex: number,
    toggleInfoWindowCallback: TToggleInfoWindow
  ): google.maps.Marker {
    const icon = this.getAlarmMarkerIcon();
    const marker = new google.maps.Marker({ icon, position, map: googleMap });
    marker.addListener('click', () => {
      toggleInfoWindowCallback(rideNumber, positionIndex);
    });
    return marker;
  },

  /** Creates ride map marker's infowindow */
  createRideMarkerInfoWindow(
    googleMap: google.maps.Map,
    position: IVehicleRidePosition,
    html: any
  ) {
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(position.gpsLat, position.gpsLon),
      map: null,
    });
    return overlay;
  },

  createAlarmMarkerInfoWindow(
    googleMap: google.maps.Map,
    position: IVehicleRidePosition,
    html: any
  ) {
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(position.gpsLat, position.gpsLon),
      map: null,
    });
    return overlay;
  },

  createAlarmPositionMarkerOverlay(position: IRidePosition | IVehicleRidePosition, html: any) {
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(position.gpsLat, position.gpsLon),
      map: null,
      position: { topLeft: true },
    });
    return overlay;
  },

  createEventPositionMarkerOverlay(position: IRidePosition | IVehicleRidePosition, html: any) {
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(position.gpsLat, position.gpsLon),
      map: null,
      position: { topLeft: true },
    });
    return overlay;
  },

  /** Creates ride map position marker tooltip */
  createRidePositionMarkerOverlay(position: IRidePosition | IVehicleRidePosition, html: any) {
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(position.gpsLat, position.gpsLon),
      map: null,
      position: { topLeft: true },
    });
    return overlay;
  },

  createRideNumberMarkerOverlay(position: IRidePosition | IVehicleRidePosition, html: any) {
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(position.gpsLat, position.gpsLon),
      map: null,
      position: { topLeft: true },
      floatPane: true,
    });
    return overlay;
  },

  /**
   * Create ride number overlay
   * @param {IRidePosition} position
   * @param {any} html
   * @returns {HTMLMapMarker}
   */
  createRideNumberOverlay(map: google.maps.Map, ride: IRide, html: any): HTMLMapMarker {
    const firstPosition = ride.positions[0];
    const overlay = createHTMLMapMarker({
      html,
      latLng: new google.maps.LatLng(firstPosition.gpsLat, firstPosition.gpsLon),
      map,
      position: { isNumberIcon: true },
    });
    return overlay;
  },

  /** Set ride map bounds by rides */
  setRideMapBounds(googleMap: google.maps.Map, rides: any) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    rides.map((ride: any) => {
      const firstIndex = 0;
      const lastIndex = ride.positions.length - 1;
      bounds.extend(
        new google.maps.LatLng(ride.positions[firstIndex].gpsLat, ride.positions[firstIndex].gpsLon)
      );
      bounds.extend(
        new google.maps.LatLng(ride.positions[lastIndex].gpsLat, ride.positions[lastIndex].gpsLon)
      );
      ride.positions.map((position: IVehicleRidePosition) => {
        bounds.extend(new google.maps.LatLng(position.gpsLat, position.gpsLon));
      });
    });
    googleMap.fitBounds(bounds, { bottom: 138, left: 0, right: 0, top: 0 });
    googleMap.panToBounds(bounds);
  },
};

export default GoogleMapsService;
